import React, { lazy, Suspense } from 'react';
import './App.scss';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import HeaderMain from './share/ui/header-main/header-main';
import { it_translations } from "./translations/it";
import { en_translations } from "./translations/en";
import { HeaderService } from './service/header.service';
import { Observable } from 'rxjs';
import HomePage from './pages/HomePage/HomePage';
// import AboutPage from './pages/AboutPage/about-page';
// import CallsPage from './pages/CallsPage/calls-page';
// import MagazinePage from './pages/MagazinePage/magazine-page';
// import ArticlePage from './pages/ArticlePage/article-page';
// import ProjectsPage from './pages/ProjectsPage/projects-page';
// import ProjectPage from './pages/ProjectPage/project-page';
import { IntlProvider } from 'react-intl';
import Footer from './share/ui/footer/footer';
import Loading from './share/ui/loading/loading';
// import { FAQPage } from './pages/FAQPage/FAQPage';
// import { ContactUsPage } from './pages/ContactUsPage/ContactUsPage';
// import CallPage from './pages/CallPage/call-page';
// import { CookiePrivacyPolicies } from './pages/Policies/CookiePrivacyPolicies';

const translations: any = {
  'it': it_translations,
  'en': en_translations
};

const AboutPage = lazy(() => import("./pages/AboutPage/about-page"));
const MagazinePage = lazy(() => import("./pages/MagazinePage/magazine-page"));
const FAQPage = lazy(() => import("./pages/FAQPage/FAQPage"));
const ArticlePage = lazy(() => import("./pages/ArticlePage/article-page"));
const ProjectsPage = lazy(() => import("./pages/ProjectsPage/projects-page"));
const ProjectPage = lazy(() => import("./pages/ProjectPage/project-page"));
const ContactUsPage = lazy(() => import("./pages/ContactUsPage/ContactUsPage"));
const CallsPage = lazy(() => import("./pages/CallsPage/calls-page"));
const CallPage = lazy(() => import("./pages/CallPage/call-page"));
const CookiePrivacyPolicies = lazy(() => import("./pages/Policies/CookiePrivacyPolicies"));




export class App extends React.Component<any, any>   {
  lang: string = 'it';
  constructor(props: any) {
    super(props);
    this.state = {
      lang: 'it'
    }
  }

  toggleLanguage = () => {
    this.setState((state: any) => {
      return { lang: this.getNextLang(state.lang) }
    })
  }

  getNextLang = (lang: string) => {
    switch (lang) {
      case 'it':
        return 'en'
      case 'en':
        return 'it'
      default:
        return 'it'
    }
  }

  render() {
    return (
      <React.Fragment>
        <IntlProvider locale={this.state.lang} messages={translations[this.state.lang]}>
          <HeaderService>
            {(obs: Observable<boolean>, setter: (val: boolean) => void) => {
              return (
                <React.Fragment>
                  <Router>
                    <div className="main-container bg-almostBlack">
                      <HeaderMain obs={obs} setter={setter} onToggleLanguage={this.toggleLanguage} lang={this.getNextLang(this.state.lang)} />
                      <Suspense fallback={<Loading />}>

                        <Switch  >
                          {/* FALLBACK */}
                          <Route exact path="/">
                            <Redirect to="/Home" />
                          </Route>
                          {/* HOME */}
                          <Route exact path="/Home">
                            <HomePage />
                          </Route>
                          {/* PAGE ABOUT  */}
                          <Route path="/AboutUs">
                            <AboutPage />
                          </Route>
                          {/* PAGE MAGAZINE  */}
                          <Route path="/Magazine">
                            <MagazinePage lang={this.state.lang} />
                          </Route>
                          {/* PAGE ABOUT  */}
                          <Route path="/FAQs">
                            <FAQPage lang={this.state.lang} />
                          </Route>
                          {/* PAGE ARTICLE  */}
                          <Route path="/Article">
                            <ArticlePage lang={this.state.lang} />
                          </Route>
                          {/* PAGE PROJECTS  */}
                          <Route path="/Projects">
                            <ProjectsPage lang={this.state.lang} />
                          </Route>
                          {/* PAGE CONTACT US  */}
                          <Route path="/ContactUs">
                            <ContactUsPage lang={this.state.lang} />
                          </Route>
                          <Route path="/contactus" render={() => <Redirect to="/ContactUs" />} />
                          {/* PAGE CALLS  */}
                          <Route path="/Calls">
                            <CallsPage lang={this.state.lang} />
                          </Route>
                          {/* PAGE CALL  */}
                          <Route path="/Call">
                            <CallPage lang={this.state.lang} />
                          </Route>
                          {/* PAGE PROJECT  */}
                          <Route path="/Project">
                            <ProjectPage />
                          </Route>
                          {/* PAGE POLICIES  */}
                          <Route path="/Policy">
                            <CookiePrivacyPolicies />
                          </Route>
                          <Route render={() => <Redirect to="/" />} />
                        </Switch>
                      </Suspense>
                      <Footer />

                    </div>
                  </Router>
                </React.Fragment>
              )
            }}
          </HeaderService>
        </IntlProvider>
      </React.Fragment >
    );
  }
}

export default App;
