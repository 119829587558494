import React from 'react';
import './magazine.css';
import magazineBgWebp from '../../../../assets/images/home-bg/magazine-bg.webp';
import magazineBg from '../../../../assets/images/home-bg/magazine-bg.png';
import { GenericButton } from '../../../../share/ui/generic-button/generic-button';
import { FormattedMessage } from 'react-intl';
import { GenericSection } from '../../../../share/ui/generic-section/GenericSection';
import { goToComponentBy } from '../../../../share/functions';
import { withRouter } from 'react-router-dom';

 function Magazine(props: any) {
    return (
        <GenericSection id={props.id} bgImage={magazineBg} bgImageWebp={magazineBgWebp} isLeft={true} titleFontSize="2.8vw"
            title={
                <h1 className="unselect-undrag">Art<b>s</b> Magazine</h1>
            }
            subtitle={
                <FormattedMessage id="magazine.subtitle" >
                    {(msg: string) => (
                        <h3 className="mb-1 unselect-undrag" dangerouslySetInnerHTML={{ __html: msg }}></h3>
                        )}
                </FormattedMessage>
                    }
            content={
                <FormattedMessage id="magazine.description" >
                    {(msg: string) => (
                        <p className="magazine-body unselect-undrag" dangerouslySetInnerHTML={{ __html: msg }}></p>
                    )}
                </FormattedMessage>
            }
            button={
                <GenericButton icon="icon-article" label="magazine.button" onClick={() => goToComponentBy('Magazine', props.history)} />
            }
        />
    );
}


export default withRouter(Magazine);