import React from 'react';
import './footer.scss';
import footerLogo from '../../../assets/svg/logo-claim.svg';
import { withRouter } from 'react-router-dom';
import { goToComponentBy } from '../../functions';


function Footer(props: any) {
    return (
        <footer className="main-container-footer flex j-between a-center">
            <div className="contacts w-25 flex f-column">
                <h4 className="mb-4 unselect-undrag" style={{marginTop: 0}}>Contact<span className="cc-red">s</span></h4>
                <div className="mail flex f-column cc-notWhite unselect-undrag">
                    <div className="mb-3">executive@forarts.info</div>
                    <div className="mt-3 mb-3">creative@forarts.info</div>
                    <div className="mt-3 mb-3">support@forarts.info</div>
                </div>
            </div>
            <div className="center-wrapper-footer w-50 flex f-column j-around a-center">
                <img className="footer-logo unselect-undrag" src={footerLogo} alt="footer-logo" />
                <div className="legal mt-5 cc-notWhite unselect-undrag">© {new Date().getFullYear()} - Associazione Culturale ForArts &nbsp;|&nbsp; C.F. 92076380051&nbsp; | &nbsp;Via Govone 15, 14100, Asti (AT)</div>
            </div>
            <div className="link w-25 flex f-column j-around a-end unselect-undrag cc-white">
                <div onClick={() => goToComponentBy('FAQS', props.history)}>FAQ</div>
                <div className="mt-4" onClick={() => goToComponentBy('AboutUs', props.history)}>About Us</div>
                <div className="mt-4" onClick={() => goToComponentBy('ContactUs', props.history)}>Contact Us</div>
                <div className="mt-4" onClick={() => goToComponentBy('Policy', props.history)}>Cookie &amp; Privacy</div>
            </div>
        </footer>
    );
}


export default withRouter(Footer);