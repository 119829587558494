import React from 'react';
import './about.scss';
import aboutBgWebp from '../../../../assets/images/home-bg/about-bg.webp';
import aboutBg from '../../../../assets/images/home-bg/about-bg.png';
import { GenericSection } from '../../../../share/ui/generic-section/GenericSection';
import { FormattedMessage } from 'react-intl';
import { GenericButton } from '../../../../share/ui/generic-button/generic-button';
import { goToComponentBy } from '../../../../share/functions';
import { withRouter } from 'react-router-dom';


function About(props: any) {
    return (
        <GenericSection bgImage={aboutBg} bgImageWebp={aboutBgWebp} id={props.id} isLeft={true} 
            title={
                <h1 className="mb-2 unselect-undrag">ForArt<b>s</b></h1>
            }
            subtitle={
                <h3 className="mb-1 unselect-undrag">“Adapt. Respond. Transform. <b>Succeed.</b>”:</h3>
            }
            content={
                <FormattedMessage id="about.description" >
                    {(msg: string) => (
                        <p className="mt-1 unselect-undrag" dangerouslySetInnerHTML={{ __html: msg }}></p>
                    )}
                </FormattedMessage>
            }
            button={
                <GenericButton label="about.button" icon="icon-about" onClick={() => goToComponentBy('AboutUs', props.history)} />
            }
        />
    );
}


export default withRouter(About);