import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';


// STILE GLOBALE ( INDEX )
import './styles/index.scss';
// STILE GLOBALE ( ELEMENTI )
import './styles/styles.scss';
// VARIABILI GLOBALI
import './styles/variables.scss';
// MARGIN / PADDING GLOBALI
import './styles/margin-padding.scss';
// STILE TAG CUSTOM
import './styles/custom-tags.scss';
// STILE FLEX
import './styles/flex.scss';
// WIDTH
import './styles/width.scss';
// COLORI
import './styles/colors.scss';


// STILE PRIMEREACT
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
// PRIMEREACT OVERRIDE
import './styles/primereact-override.scss';

// MAIN APP
import App from './App';


ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
