
import React from 'react';
import { Observable } from 'rxjs';
import './header-main.css';
import site_logo from '../../../assets/images/header-icons/icon.png';
import site_naming from '../../../assets/images/header-icons/hz-logo-new.svg';
import { FormattedMessage } from 'react-intl';
import { goToComponentBy } from '../../functions';
import { withRouter } from 'react-router-dom';
import { LanguageSelector } from '../language/language';

class HeaderMain extends React.Component<({ obs: Observable<boolean>, setter: (val: boolean) => void, onToggleLanguage: () => void, lang: string } | any), any> {
    // @Output() openMenu: EventEmitter<any> = new EventEmitter<any>();
    emitOpenMenu() {
        this.props.setter(true);
    }

    env: string = "";

    searchOpen: boolean = false;

    buttons: { title: string, id: string }[] = [
        {
            title: 'About Us',
            id: "AboutUs"
        },
        {
            title: 'Projects',
            id: "Projects"
        },
        {
            title: 'Call For Artists',
            id: "Calls"
        },
        {
            title: 'Magazine',
            id: "Magazine"
        },
        // {
        //     title: 'Gallery',
        //     id: "CallForArtists"
        // },
    ]


    constructor(props: any) {
        super(props);
        this.state = { show: false, searchOpen: false };
    }

    componentDidMount() {
        // console.log(this.props.obs);
        this.props.obs.subscribe((x: any) => {
            // console.log("Getting value: ", x)
            this.setState({ show: x });
        })


    }




    render() {

        // return <div onClick={() => this.props.setter(!this.state.show)} >CIAOO</div>
        const mainClass = `main-container-header flex f-wrap f-column ${!this.state.show ? 'hidden' : ''}`;
        const subClass = `left-icons-section ${!this.state.searchOpen ? 'logo-up' : 'logo-down'}`;
        return (
            <React.Fragment>
                <header className={mainClass} >
                    {/* < !--MAIN ROW-- > */}
                    < div className="main-row" >

                        <div className={subClass}>
                            <img className="text-logo unselect-undrag" src={site_naming} onClick={() => { goToComponentBy('', this.props.history) }} alt="forarts-name" />
                            {
                                process.env.NODE_ENV.toUpperCase() === 'DEVELOPMENT'
                                &&
                                <div className="env-label" ><FormattedMessage id="global.ambiente" />: {process.env.NODE_ENV.toUpperCase()}</div >
                            }
                        </div >
                        <div className="right-buttons-section">

                            {/* <!-- SECTION BUTTONS --> */}
                            <nav className="menu-section button-section">
                                {
                                    this.buttons.map((x: any, index: number) => {
                                        return (
                                            <div
                                                key={`header-button-${index}`}
                                                className="section-button unselect-undrag"
                                                onClick={() => { goToComponentBy(x.id, this.props.history) }}
                                            >
                                                {x.title}
                                            </div>
                                        )
                                    })
                                }
                            </nav>

                            {/* LANGUAGE SELECTOR */}
                            <LanguageSelector onToggle={this.props.onToggleLanguage} lang={this.props.lang} />
                            {/* <!-- ICONS --> */}
                            <div className="menu-section">
                                {/* LOGO */}
                                {/* <img className="logo unselect-undrag" src={site_logo} alt="forarts-logo" onClick={() => { goToComponentBy('', this.props.history) }} />
                                <div className="icon-wrapper">
                                </div> */}

                            </div >
                        </div >
                    </div >
                    <div className={`mobile-row flex j-between ${this.state.show ? '' : 'hidden'}`}>
                        <div className="header-shadow-cover"></div>
                        {/* <div className="menu-section button-section"> */}
                        {
                            this.buttons.map((x: any, index: number) => {
                                return (
                                    <div
                                        key={`header-button-${index}`}
                                        className="section-button unselect-undrag"
                                        onClick={() => { goToComponentBy(x.id, this.props.history) }}
                                    >
                                        {x.title}
                                    </div>
                                )
                            })
                        }
                        {/* </div> */}
                    </div>
                </header >
                <header className="mobile-header-phisic"></header>
            </React.Fragment>
        )

    }
}




export default withRouter(HeaderMain);
