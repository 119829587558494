

export const PagesDescriptions: PagesDescription = {
    HOME: {
        title: "ForArts | 3D Virtual Art Galleries & Call for Artists",
        description: "Our Vision is to allow contemporary artists and galleries to curate and promote 3D virtual exhibitions: for making art accessible anywhere, anytime."
    },
    ABOUT_US: {
        title: "About Us | ForArts Cultural Association",
        description: "Association for the Safeguard of Contemporary artists and Culture, with the aim of making art Accessible and Meritocratic through Digital."
    },
    COOKIE_PRIVACY: {
        title: "Cookie & Privacy Policy | ForArts",
        description: "Cookies or other identifiers consist of portions of code placed on the device placed on the user’s device. More info on the website page."
    },
    PROJECTS: {
        title: "Projects | ForArts",
        description: "Virtual Art Exhibitions, Contemporary Art Call for Artists for Emerging Artists and more. Discover ForArts Projects."
    },
    CONTACT_US: {
        title: "Contact Us | ForArts",
        description: "Artist or Gallery? Create your true to scale Art Exhibitions in 3D websites fully dedicated to your Collection! Contact Us."
    },
    MAGAZINE: {
        title: "Arts Magazine | ForArts",
        description: "Online Magazine for the Contemporary Art and the Emerging Artists. A journey into the artistic landscape. Read the articles."
    },
    FAQS: {
        title: "FAQs | ForArts",
        description: "Discover how ForArts can help you Curating Virtual Art Experiences for Enhancing art and Increasing your Visibility internationally."
    },
    CALL_FOR_ARTIST: {
        title: "Call for Artists | ForArts",
        description: "Contemporary Art Calls for Emerging Artists. Discover the next Calls and Candidates for the Active ones."
    },
}



export interface PageDescription {
    title: string,
    description: string
}
export interface PagesDescription {
    HOME: PageDescription,
    ABOUT_US: PageDescription,
    COOKIE_PRIVACY: PageDescription,
    PROJECTS: PageDescription,
    CONTACT_US: PageDescription,
    MAGAZINE: PageDescription,
    FAQS: PageDescription,
    CALL_FOR_ARTIST: PageDescription,
}