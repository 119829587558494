import React from 'react';
import { Helmet } from 'react-helmet';
import { PagesDescriptions } from '../pages-descriptions';
import About from './components/about/about';
import Call from './components/call/call';
import { Carousel } from './components/carousel/carousel';
import Magazine from './components/magazine/magazine';
import './HomePage.css';


export default function HomePage(props: any) {
    return (
        <React.Fragment>
            <Helmet>
                {/* Page Title */}
                <title>{PagesDescriptions.HOME.title}</title>
                {/* Meta Description */}
                <meta
                    name="description"
                    content={PagesDescriptions.HOME.description}
                />
            </Helmet>
            <div className="home-page-container" >
                <Carousel />
                <About id="About" />
                <Call id="CallForArtists" />
                <Magazine id="Magazine" />
                {props.children}
            </div>
        </React.Fragment>
    )
}