import React from 'react';
import { FormattedMessage } from 'react-intl';
import './generic-button.scss';

export function GenericButton(props: {
    buttonPosition?: ('LEFT' | 'CENTER' | 'RIGHT'),
    onClick: () => void,
    label?: string,
    icon?: string,
    marginTop?: string,
    width?: string,
    fontSize?: string,
    style?: React.CSSProperties | undefined
}) {

    // Classe main container
    const mainClass = ('generic-button unselect-undrag flex '
        + ((props.icon
            &&
            props.label) ? 'generic-button-animation ' : (props.icon ? 'generic-button-only-icon' : ''))
    );

    // Classe icona
    const iconClass = ('button-icon unselect-undrag flex-center-all '
        + props.icon
    );

    return (
        <div
            onClick={props.onClick}
            className={mainClass}
            aria-label={props.label}
            style={{
                marginTop: (props.marginTop ? props.marginTop : undefined),
                fontSize: (props.fontSize ? props.fontSize : undefined),
                ...props.style
            }}>
            <div className="flex j-center unselect-undrag" style={{ width: '100%', position: 'relative' }}>
                {
                    props.icon
                    &&
                    <i className={iconClass}></i>
                }
                {
                    props.icon
                    &&
                    props.label
                    &&
                    <span className="generic-button-hided-divider unselect-undrag" ></span>
                }
                {
                    props.label
                    &&
                    <div className="generic-button-text unselect-undrag">
                        <FormattedMessage id={props.label} />
                    </div>
                }
            </div>
        </div>
    )
}