import React from 'react';
import './GenericSection.scss';

export interface GenericSectionProps { id: string, isLeft: boolean, bgImage: string, bgImageWebp?: string, content?: any, title?: any, subtitle?: any, button?: any, titleFontSize?: string }

export class GenericSection extends React.Component<GenericSectionProps, { titleActive: boolean, subtitleActive: boolean, contentActive: boolean }> {

    title: HTMLElement | null = null;
    subtitle: HTMLElement | null = null;
    content: HTMLElement | null = null;

    constructor(props: GenericSectionProps) {
        super(props);
        this.state = {
            titleActive: false,
            subtitleActive: false,
            contentActive: false,
        }
    }

    componentDidMount() {
        this.addListener();
        this.listenerFunc();
    }

    // ANIMATION LISTENER
    addListener = () => {
        window.addEventListener('scroll', this.listenerFunc, { passive: true });
    }

    // ANIMATION FUNCTION
    listenerFunc = () => {
        this.title = document.getElementById((this.props.id + 'title'));
        this.subtitle = document.getElementById((this.props.id + 'subtitle'));
        this.content = document.getElementById((this.props.id + 'content'));
        if (this.props.title && this.title) {
            const titleA = isInViewport(this.title);
            // console.log(titleA);
            if (titleA) {
                // console.log("Title active")
                this.setState({ titleActive: true });
                this.removeListenerIfDone()
            }
        }
        if (this.props.subtitle && this.subtitle) {
            const subA = isInViewport(this.subtitle);
            if (subA) {
                // console.log("Subtitle active")
                this.setState({ subtitleActive: true });
                this.removeListenerIfDone()
            }
        }
        if (this.props.content && this.content) {
            const contA = isInViewport(this.content);
            // console.log(contA);
            if (contA) {
                // console.log("Content active")
                this.setState({ contentActive: true });
                this.removeListenerIfDone()
            }
        }
    }



    removeListenerIfDone = () => {
        this.setState((state) => {
            if ((state.titleActive || !this.props.title) && (state.subtitleActive || !this.props.subtitle) && (state.contentActive || !this.props.content)) {
                window.removeEventListener('scroll', (e) => {
                    console.debug("Listener removed");
                });
            }
        })
    }

    render() {
        const mainClass = "main-container-generic main-container-generic-padding bg-almostBlack flex unselect "
            + (this.props.isLeft ? 'generic-main-left ' : 'generic-main-right ')
            + (this.state.titleActive ? '' : 'main-container-generic-hide ')

        const titleClass = "generic-title-container "
        const subtitleClass = "generic-subtitle-container "
        const contentClass = "generic-content-container "

        return (
            <section id={this.props.id} className={mainClass}>
                {
                    !this.props.isLeft
                    &&
                    <div className="generic-left-image-container flex a-start j-start unselect-undrag">
                        <picture>
                            <source srcSet={this.props.bgImageWebp} type="image/webp" />
                            <source srcSet={this.props.bgImage} type="image/jpeg" />
                            <img className="generic-image" loading="lazy" src={this.props.bgImage} alt={`${this.props.id}.jpg`} />
                        </picture>
                    </div>
                }
                <div className="generic-text-main-container flex" >
                    {
                        this.props.title &&
                        <div id={(this.props.id + 'title')} style={{ fontSize: this.props.titleFontSize ? this.props.titleFontSize : undefined }} className={titleClass}>
                            {this.props.title}
                        </div>
                    }
                    {
                        this.props.subtitle &&
                        <div id={(this.props.id + 'subtitle')} className={subtitleClass}>
                            {this.props.subtitle}
                        </div>
                    }
                    <div id={(this.props.id + 'content')} className={contentClass}>
                        {this.props.content && this.props.content}
                    </div>
                    {
                        this.props.button &&
                        <div className="generic-bottom-button-container flex" >
                            {this.props.button}
                        </div>
                    }
                </div>
                {
                    this.props.isLeft
                    &&
                    <div className="generic-right-image-container flex a-start j-end unselect-undrag">
                        <picture>
                            <source srcSet={this.props.bgImageWebp} type="image/webp" />
                            <source srcSet={this.props.bgImage} type="image/jpeg" /> 
                            <img className="generic-image" loading="lazy" src={this.props.bgImage} alt={`${this.props.id}.jpg`} />
                        </picture>
                    </div>
                }
            </section >
        )
    }
}


function isInViewport(element: HTMLElement) {
    const ih = window.scrollY + window.innerHeight;
    const res = ih >= getOffset(element).top + 150;
    // console.log("Screen point, element top: ", ih, res);
    return res;
}


function getOffset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    // console.log(rect.top + window.scrollY)
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY
    };
}