import React from 'react';
import style from './language.module.scss';

export function LanguageSelector(props: { lang: string, onToggle: () => void }) {
    return (
        <div className={`${style.container} unselect-undrag`} onClick={props.onToggle} >{props.lang.toUpperCase()}</div >

    )
}

