import React from 'react';
import './call.css';
import callBgWebp from '../../../../assets/images/home-bg/call-bg.webp';
import callBg from '../../../../assets/images/home-bg/call-bg.png';
import { GenericButton } from '../../../../share/ui/generic-button/generic-button';
import { GenericSection } from '../../../../share/ui/generic-section/GenericSection';
import { FormattedMessage } from 'react-intl';
import { goToComponentBy } from '../../../../share/functions';
import { withRouter } from 'react-router-dom';


function Call(props: any) {
    return (
        <GenericSection id={props.id} bgImage={callBg} bgImageWebp={callBgWebp} isLeft={false}
            title={
                    <h1 className="mb-2 unselect-undrag">Call For Artist<b>s</b></h1>
            }
            subtitle={
                <FormattedMessage id="call.subtitle" >
                    {(msg: string) => (
                        <h3 className="mb-1 unselect-undrag" dangerouslySetInnerHTML={{ __html: msg }}></h3>
                        )}
                </FormattedMessage>
                    }
            content={
                <FormattedMessage id="call.description" >
                    {(msg: string) => (
                        <p className="mt-1 unselect-undrag" dangerouslySetInnerHTML={{ __html: msg }}></p>
                    )}
                </FormattedMessage>
            }
            button={
                <GenericButton label="call.button" icon="icon-call" onClick={() => { goToComponentBy('Calls', props.history) }} />
            }
        />
    )
}


export default withRouter(Call);