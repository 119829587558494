import React from 'react';
import { Observable } from 'rxjs';

export class HeaderService extends React.Component<{ children: (observable: any, setter: any) => JSX.Element }, any> {

    showHeader: boolean = false;

    observable: Observable<boolean>;

    subscribers: any[] = [];

    ts: number = 0;

    constructor(props: any) {
        super(props);
        this.state = { showHeader: true }
        this.observable = new Observable<boolean>((x) => { this.subscribers.push(x); x.next(this.state.showHeader) });
    }

    setShowHeader = (val: boolean) => {
        // console.log("Re setting state")
        this.setState((state: any) => {

            const newVal = val;
            this.subscribers.forEach(s => s.next(newVal));
            return { showHeader: newVal }
        })
    }

    componentDidMount() {
        window.addEventListener("wheel", (e) => {
            this.setState((state: any) => {
                var show = isScrollingUp(e);
                // console.log("Show: ", show)
                if (state.showHeader !== show) {
                    this.setShowHeader(show);
                }
            })

        }, { passive: true });


        window.addEventListener("touchstart", (e: any) => {
            // console.log("Event start", e);
            this.ts = e.changedTouches[0].clientY;
            // console.log("ts: ", this.ts);

        }, { passive: true });
        window.addEventListener("touchend", (e: any) => {
            var te = e.changedTouches[0].clientY;
            var show: any = undefined;
            if (this.ts >= te + 15) {
                show = false;
            } else if (this.ts < te - 15) {
                show = true;
            }


            if (show !== undefined) {
                this.setState((state: any) => {
                    if (state.showHeader !== show) {
                        this.setShowHeader(show);
                    }
                })
            }

        })
    }


    render() {
        return (
            <React.Fragment >
                { this.props.children(this.observable, this.setShowHeader)}
            </React.Fragment >
        )
    }
}



function isScrollingUp(event: any) {
    if (checkScrollDirectionIsUp(event)) {
        // console.log("UP");
        return true;
    } else {
        // console.log("DOWN");
        return false;
    }
}

function checkScrollDirectionIsUp(event: any) {
    // console.log("Event: ", event);
    if (event.wheelDelta) {
        return event.wheelDelta > 0;
    }
    return event.deltaY < 0;
}