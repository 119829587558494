import React from 'react';
import './carousel.css';
import { FormattedMessage } from 'react-intl';
import { GenericButton } from '../../../../share/ui/generic-button/generic-button';
import { goToBlank, goToComponentBy } from '../../../../share/functions';


export class Carousel extends React.Component<any, { fase: number }> {
    totalPage: number = 0;

    intervalDuration: number = 7500

    interval: any;

    pagine: any[] = [
        {
            mainTitle: "carousel.title_1",
            mainTitleMobile: "carousel.title_1",
            description: "carousel.description_1",
            // classe css
            background: "image1"
        },
        {
            mainTitle: "carousel.title_2",
            mainTitleMobile: "carousel.title_2",
            description: "carousel.description_2",
            // classe css
            background: "image2"
        },
        {
            mainTitle: 'carousel.title_3',
            mainTitleMobile: 'carousel.title_3',
            description: "carousel.description_3",
            // classe css
            background: "image3"
        },
    ];


    constructor(props: any) {
        super(props);
        this.state = {
            fase: 0
        }
        this.totalPage = this.pagine.length;
    }

    componentDidMount() {
        this.animation();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }


    animation = () => {
        this.interval = setInterval(() => {
            // console.log('RUNNING INTERVAL');
            this.nextNumber();
        }, this.intervalDuration);

    }

    nextNumber = () => {
        this.setState((state) => {
            switch (state.fase) {
                case this.totalPage - 1:
                    return { fase: 0 };
                default:
                    return { fase: state.fase + 1 }

            }
        });
    }


    setPage = (page: number) => {
        // FERMO L'INTERVALLO
        clearInterval(this.interval);
        // IMPOSTO IL NUMERO DELLA SERIE
        this.setState({ fase: page });
        // FACCIO RIPARTIRE L'INTERVALLO
        this.animation();
    }

    next = () => {
        // FERMO L'INTERVALLO
        clearInterval(this.interval);
        // IMPOSTO IL PROSSIMO NUMERO DELLA SERIE
        this.nextNumber();
        // FACCIO RIPARTIRE L'INTERVALLO
        this.animation();
    }

    back = () => {
        // FERMO L'INTERVALLO
        clearInterval(this.interval);
        // IMPOSTO IL PROSSIMO NUMERO DELLA SERIE a X -1
        if (this.state.fase > 0) {
            this.setState((state) => { return { fase: (state.fase - 1) } });
        } else {
            this.setState({ fase: (this.totalPage - 1) });
        }
        // FACCIO RIPARTIRE L'INTERVALLO
        this.animation();
    }


    getMargin = () => {
        switch (this.state.fase) {
            case 0:
                return '0';
            default:
                return '-' + this.state.fase + '00vw';

        }
    }

    render() {

        const style = { marginLeft: this.getMargin(), width: `${(this.totalPage * 100)}vw` };
        return (
            <React.Fragment>
                <section className="m-main-container" style={{ position: 'relative' }}
                    onMouseDown={(e) => { e.stopPropagation(); clearInterval(this.interval) }} onTouchStart={(e) => { e.stopPropagation(); clearInterval(this.interval) }}
                    onMouseUp={(e) => { e.stopPropagation(); this.next() }} onTouchEnd={(e) => { e.stopPropagation(); this.next() }}
                >
                    <div className="side left" onClick={(e) => { e.stopPropagation(); this.back() }}>
                        <i className="icon-arrow_left carousel-arrows"></i>
                    </div>
                    <div className="side right" onClick={(e) => { e.stopPropagation(); this.next() }}>
                        <i className="icon-arrow_right carousel-arrows"></i>
                    </div >
                    <div className="bottom-dots-container">
                        {this.pagine.map((i, di) => {

                            const className = ('dot ' + (di === this.state.fase ? "dot-active" : ""))
                            return (
                                <div key={`dot-${di}`} onClick={() => this.setPage(di)} className="dot-container">
                                    <div className={className}></div>
                                </div>
                            )
                        })}
                    </div >
                    {/* TODO CREARE CLASSE CONTAINER DEL BOTTONE PER  */}
                    <div style={{ width: '100%', zIndex: 500, position: 'absolute', top: 'calc(90vh - 80px)' }} className="flex-center-all">
                        <GenericButton icon="icon-about" label={this.state.fase === 1 ? "general.contactus" : "carousel.button"}
                            onClick={() => { (this.state.fase === 2 ? goToComponentBy("ContactUs", this.props.history) : goToBlank("https://galleriadavidelajolo.forarts.it")) }}
                            style={{}} />
                    </div>

                    <div className="main-container-carousel" style={{ width: `${(this.totalPage * 100)}vw` }} >
                        < div style={style} className="main-container-carousel"  >
                            {
                                this.pagine.map((i, index) => {

                                    const className = `item color-${index}`;
                                    return (
                                        // backgroundImage: `url(${i.background})`
                                        <div key={`page-${index}`} className={`${className} ${i.background}`} style={{}} >
                                            <div className="item" style={{ width: 'inherit', height: 'inherit', backgroundColor: '#00000094' }} >
                                                <FormattedMessage id={i.mainTitle} >
                                                    {(msg: string) => (
                                                        <div className="main-title unselect-undrag" dangerouslySetInnerHTML={{ __html: msg }}></div>
                                                    )}
                                                </FormattedMessage>
                                                <FormattedMessage id={i.mainTitleMobile} >
                                                    {(msg: string) => (
                                                        <div className="main-title-mobile unselect-undrag" dangerouslySetInnerHTML={{ __html: msg }}></div>
                                                    )}
                                                </FormattedMessage>
                                                <FormattedMessage id={i.description} >
                                                    {(msg: string) => (
                                                        <div className="description unselect-undrag" dangerouslySetInnerHTML={{ __html: msg }}></div>
                                                    )}
                                                </FormattedMessage>
                                            </div>
                                        </div >
                                    )
                                })
                            }
                        </div >
                    </div >
                </section>
            </React.Fragment >
        )
    }
}
