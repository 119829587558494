

export const it_translations = {
    // GLOBAL
    "global.ambiente": "Ambiente",
    "general.contactus": "Contattaci",
    "general.expand": "Espandi",
    "general.reduce": "Riduci",
    // CAROUSEL
    "carousel.title_1": "Galleria Virtuale Davide Lajolo",
    "carousel.description_1": "Un’<b>esperienza</b> espositiva <b>virtuale</b> a Palazzo Crova. <br/> Teatro del un connubio tra <b>tradizione</b> e <b>innovazione</b>. "
        + "<br/>La cultura monferrina declinata tra <b>arte</b> e <b>fotografia</b>.",
    "carousel.title_3": "La Tua Galleria Virtuale",
    "carousel.description_3": "Creiamo lo spazio perfetto per l'Arte. <br/>Dai voce alla <b>creatività</b>. <br/>Scopri come creare la <b>tua</b> Galleria D'Arte Virtuale.",
    "carousel.title_2": "I Nostri Progetti",
    "carousel.description_2": "<b>Call for Artists</b> d'Arte Contemporanea. <br/>Per un’arte <b>accessibile</b>, <b>inclusiva</b> e <b>innovativa</b>. "
        + "<br> Scopri le Call e candidati a quelle attive!",
    "carousel.button": "Visita La Mostra",
    "projects.button": "Voglio saperne di più",
    // MAGAZINE
    "magazine.subtitle": 'La rivista online di ForArts',
    "magazine.description": "Rivista online per l’<b>Arte</b>, l’<b>Artista</b> e l’<b>Innovazione</b>. <br /><br /> Per l’Arte contemporanea: <i>spunti</i>, <i>curiosità</i>, "
        + "<i>dati</i> e <i>fatti</i> sul mondo dell’<b>Arte</b>. <br/><br/> Per l’Artista emergente: una guida dedicata alla crescita della figura artistica <b>professionale</b>."
        + "<br/><br/>Per l’<b>Innovazione</b> artistica <b>digitale</b>: un viaggio nelle Gallerie Virtuali <b><i>ForArts</i></b>!",
    "magazine.button": "Leggi Gli Articoli",
    // SOCIAL 
    "social.description": "<b>Condividi</b> le tue Opere con la nostra Community! <br /><br /> <b>Scopri</b> le news su Bandi, Progetti ed Eventi! <br /><br /> Entra a far parte del mondo <b>ForArts</b>!",
    // CALL 
    "call.subtitle": 'Verso un\'arte inclusiva e meritocratica',
    "call.description": "Bandi d’Arte Contemporanea per Artisti Emergenti: <b>scopri</b> i prossimi Bandi e <b>candidati</b> a quelli attivi."
        + "<br /><br /> Call for Artists per esposizioni in contesti <b>unici</b> nelle Gallerie Virtuali <b><i>ForArts</i></b>: Mostra la tua Arte."
        + "<br /><br /> Sei un <b>Pittore</b>, un <b>Fotografo</b>, uno <b>Scultore</b>, un <b>Artista Digitale</b> o un <b>VideoMaker</b>? <br /><br /> <b>Inviaci la tua candidatura!</b>",
    "call.button": "Scopri i Bandi",
    // ABOUT
    "about.description": "La nostra <b>Mission</b> è mettere in risalto la produzione dell’Artista Emergente in chiave <b>tecnologica</b>."
        + "<br /><br /> Ci adattiamo alle <b>nuove</b> esigenze <b>comunicative</b> e <b>digitali</b> dell’Arte Contemporanea, attraverso le nostre gallerie <b>virtuali</b>."
        + "<br /><br /> Ispirato a 360° dall’Arte <b>Visiva</b> e dedicato a chi la crea: <i>Pittori</i>, <i>Scultori</i>, <i>Fotografi</i>, <i>Artisti Digitali</i> e <i>VideoMakers</i>.",
    "about.button": 'Scopri Il Progetto',
    // ABOUT PAGE MENU
    "about.page.menu_1": "Il Progetto",
    "about.page.menu_2": "Æ ArtEtica",
    "about.page.menu_3": "Mission",
    "about.page.menu_4": "Associazione Culturale ForArts",
    "about.page.menu_5": "Vision",
    "about.page.menu_6": "Galleria Virtuale Davide Lajolo",
    "about.page.menu_7": "ForArts Exhibitions",
    // ABOUT PAGE
    "about.page.head_1": "Il Progetto",
    "about.page.subhead_1": "Cultura e Innovazione",
    "about.page.description_1": 'ForArts nasce nel marzo 2019 dall’idea di Edoardo Glinac e Gianluca Lammino, laureandi in <i>"Design e Comunicazione Visiva"</i> al Politecnico di Torino e in'
        + ' <i>"Business & Management"</i> all\'Università degli Studi di Torino, accomunati dalla passione dell’Arte. <br />Il nostro grande alleato è Francesco Bellini, diplomato in <i>"Pro Audio Engineering"</i> '
        + 'al CPM Institute di Milano. <br/><br/> Quest\'<b>unione</b> di competenze permette di dar vita alle nostre <b>idee</b>, in un processo che ricorda il tumultuoso percorso che accompagna '
        + 'l\'artista dalla <b>scintilla</b> alla tela. <br/><br/>  ForArts nasce con l’idea di valorizzare l’arte e l’artista emergente, attraverso una piattaforma digitale dedicata '
        + 'e tramite esperienze espositive in cui l\'<b>arte</b>, la <b>cultura</b>, la <b>sostenibilità</b> e la <b>tecnologia</b> sono driver sempre presenti.',
    "about.page.head_2": "Æ ArtEtica",
    "about.page.subhead_2": "L'Arte Solidale e Sostenibile",
    "about.page.description_2": 'L’evento <b><i>“Æ ArtEtica | L’Arte Solidale e Sostenibile”</i></b> si è tenuto il 19 Luglio 2019 presso ' +
        '<i>FuoriLuogo Asti</i>. <br /><br /> Ha raccolto la candidatura di oltre 50 artisti, selezionati e valutati da una giuria d’<b>eccellenza</b>, presieduta ' +
        'da <b><i>Davide Fabbro</i></b>, Dottore Commercialista e Art Advisor. <br /><br />Oltre 400 visitatori al vernissage hanno assistito al lancio della Missione ForArts.',
    "about.page.head_3": "Mission",
    "about.page.subhead_3": "Innovare per Preservare",
    "about.page.description_3": '“Adapt. Respond. Transform. <b><fs>Succeed</fs></b>.” <br /><br />Come recita il nostro slogan, la Mission è ' +
        'di esaltare la produzione dell’artista <b>emergente</b>, adattandola alle ' +
        'nuove esigenze <b>comunicative</b>  e <b>digitali</b>  dell’arte contemporanea.',
    "about.page.head_4": "Associazione Culturale ForArts",
    "about.page.subhead_4": "Per la crescita dell'artista",
    "about.page.description_4": 'L’Associazione Culturale senza scopo di lucro ForArts nasce nell’agosto 2020. <br /><br />Il fine è promuovere l’<b>arte</b>, la <b>cultura</b> ' +
        'e l’<b>artista</b> di qualsiasi espressione, digitale e non: <br /> <b>Pittori</b>, <b>Scultori</b>, <b>Fotografi</b>, <b>Grafici</b> e <b>Videomakers</b>.',
    "about.page.head_5": "Vision",
    "about.page.subhead_5": "Verso Un'Arte Accessibile e Meritocratica",
    "about.page.description_5": 'La Visione dell’Associazione è di lavorare per un’arte <b>accessibile</b>, <b>inclusiva</b> e che ne ripensi <b>funzione</b>, <b>fruizione</b> e <b>spazi</b> con la ' +
        'tecnologia al suo servizio. <br/><br/>Decliniamo l’innovazione digitale al servizio di un’arte <b>meritocratica</b> con le Gallerie Virtuali ForArts.',
    "about.page.head_6": "Galleria Virtuale Davide Lajolo",
    "about.page.subhead_6": "L'Arte e La Cultura Monferrina ",
    "about.page.description_6": 'In occasione del <i>Festival del Paesaggio Agrario XII Edizione</i>, ForArts presenta la prima Galleria ' +
        'd’Arte Virtuale italiana inserita in un sito web e dà vita alla sua visione.<br/><br/> La <b><i>Galleria Virtuale Davide Lajolo</i></b> è la ricostruzione della pianta del piano museale di Palazzo Crova, Nizza Monferrato, ' +
        'che ospita la <b><i>Collezione Davide Lajolo Art ‘900</i></b> con opere di grandi artisti italiani del ‘900.<br/><br/> La Galleria ospita opere di alcuni artisti della collezione tra cui <b>Guttuso</b>, <b>Nerone</b>, <b>Vacchi</b>, <b>Zigaina</b> ' +
        'ed una mostra fotografica sul Monferrato a cura dell’<i>Agenzia Visiva Mikelerio</i>.',
    "about.page.head_7": "ForArts Exhibitions",
    "about.page.subhead_7": "L'Arte Guarda al Futuro",
    "about.page.description_7": 'ForArts Exhibitions è una piattaforma (attualmente in sviluppo) di social art dedicata all\'allestimento di mostre ed <b>esperienze virtuali</b> in gallerie 3D personalizzabili, condivisibili e completamente navigabili. <br /><br />' +
    'La piattaforma è dedicata ad <b>artisti</b> (pittori, artisti digitali, fotografi, disegnatori e videomakers), <b>spazi espositivi</b> (musei, gallerie pubbliche e private) e <b>professionisti</b> del mondo dell\'arte (art advisors, collezionisti, critici e curatori). <br /><br />' +
    'ForArts Exhibitions permetterà di <b>allestire</b> e promuovere gallerie virtuali, di <b>collaborare</b> nell\'allestimento con altri utenti, di <b>candidarsi</b> a Call for Artists e di <b>monetizzare</b> la produzione artistica. <br /><br />' +
    'Presto lanceremo una fase di test: sei interessato a candidarti oppure vuoi saperne di più sulle <b>tempistiche</b> e sulle <b>funzionalità</b>? Contattaci tramite la mail <a href=”mailto:creative@forarts.info” target=”_blank”>creative@forarts.info</a>, il nostro team è a tua disposizione.',
    // MAGAZINE PAGE
    'magazine.page.head.1': 'Arts Magazine Overview',
    'magazine.page.subhead.1': 'Arte, Innovazione e Sostenibilità',
    'magazine.page.preview.1': 'Arts Magazine è la rivista online dell’Associazione Culturale senza scopo di lucro ForArts.' +
        'È il Connubio tra Arte Contemporanea, Innovazione e Sostenibilità: i capisaldi del Progetto.' +
        'Progetto la cui visione nasce dall’esigenza di ridare voce a colui cui si deve la produzione artistica contemporanea: l’artista.',
    'magazine.page.head.2': 'Rivista Online #Arte',
    'magazine.page.subhead.2': 'Una Panoramica Sul Mondo',
    'magazine.page.preview.2': 'La creazione e lo sviluppo del concept della Rivista online #Arte è dettata da esigenze di natura multipla.' +
        'In primis, vi è l’esigenza di creare uno strumento che possa efficacemente comunicare con gli attori del mondo artistico fornendo, agli stessi, strumenti in grado:',
    // ARTICLE PAGE 
    'article.page.head': 'Arts Magazine Overview',
    'article.page.subhead': 'Arte, Innovazione e Sostenibilità',
    'article.page.article': '<i><b>Arts Magazine</b></i> è la rivista online dell’Associazione Culturale senza scopo di lucro <b><i>ForArts</i></b>. <br/>' +
        'E’ Il Connubio tra Arte <b>Contemporanea</b>, <b>Innovazione</b> e <b>Sostenibilità</b>: i capisaldi del Progetto. <br/>' +
        'Progetto la cui visione nasce dall’esigenza di ridare voce a colui cui si deve la produzione artistica contemporanea: l’<b>artista</b>. <br/>' +
        'Attraverso un metodo comunicativo innovativo, studiato ad hoc per adattare le nuove esigenze dell’artista contemporaneo. <br/>' +
        'Con le <b>Gallerie Virtuali <i>ForArts</i></b> è ora possibile creare spazi d’arte appositamente studiati per l’artista e la sua arte: abbattendo i tipici costi di esposizione e promozione ' +
        'e rendendo l’arte digitale uno strumento <b>meritocratico</b>, <b>accessibile</b> e <b>funzionale</b> alla crescita dell’artista. <br/>' +
        'Sarà ora possibile, per l’artista emergente, avere una galleria d’arte solitaria ed il proprio portfolio a portata di sito web, da promuovere tramite il ' +
        'proprio CV artistico, i propri canali social e il proprio sito web o da diffondere a critici e operatori del mondo dell’arte. <br/>' +
        'La galleria sarà comunque eternamente presente sul sito ufficiale di <b><i>ForArts</i></b>, nella sezione Gallerie d’Arte Virtuali. <br/>' +
        'Un semplice sito web, completamente dedicato ad un’<b>esperienza</b> espositiva studiata sulle esigenze dell’artista: un metodo <b>innovativo</b>, nato in tempi di Covid-19, ' +
        'per rendere la tecnologia un mezzo al servizio dell’arte. <br/>Per rendere l’arte sempre fruibile e accessibile: basterà una connessione internet per avere accesso alla galleria. <br/>' +
        'Per rendere l’arte meritocratica: abbattendo i tipici costi di esposizione che oggi rendono l’arte elitaria e aumentandone il potere di diffusione grazie alla <b>tecnologia</b>.  <br/>' +
        'Per rendere l’arte uno strumento di diffusione <b>culturale</b>: Visita la <b><i>Galleria Virtuale Davide Lajolo</i></b> e scopri come funziona: <a href=\"galleriadavidelajolo.forarts.it\">galleriadavidelajolo.forarts.it</a> ' +
        'e contattaci per la tua galleria: <a href=\"forarts.it/ContactUs\" >forarts.it/ContactUs</a> <br/>Con lo scopo di rendere nota la produzione dell’Artista Emergente. <br/>Dedicato all’arte visiva e ' +
        'digitale di qualsiasi espressione e tecnica: <b>pittura</b>, <b>scultura</b>, <b>fotografia</b>, <b>grafica</b> e <b>video</b>. <br/><i>Arts Magazine</i> abbraccia il contesto artistico in toto, al fine di proporne una <b>sintesi</b> che la renda uno ' +
        'strumento utile, efficace ed efficiente per la <b>Trasformazione</b> e la <b>Crescita</b> dell’Artista Emergente. ' +
        '<br/><br/> <floatright>Associazione Culturale ForArts</floatright>',
    // PROJECTS PAGE
    'projects.page.head_1': 'Galleria Virtuale "Davide Lajolo"',
    'projects.page.subhead_1': 'Un Viaggio alla Scoperta della Cultura Monferrina',
    'projects.page.releaseDate_1': '24 Ottobre 2020',
    'projects.page.body_1': 'In occasione del “Festival del Paesaggio Agrario XII Edizione 2020”, l’Associazione senza scopo di lucro ForArts lancia ed inaugura la prima galleria Virtuale italiana completamente navigabile in un sito web. <br /><br />' +
        'Il progetto, nato in collaborazione con l’Associazione Culturale Davide Lajolo, l’Associazione per il Patrimonio dei Paesaggi Vitivinicoli di Langhe, Roero e Monferrato, organizzatori del Festival, e con il contributo artistico dell’Associazione FuoriLuogo Asti ' +
        'e dell’Agenzia Visiva Mikelerio, porta alla creazione della Galleria Virtuale Davide Lajolo. <br /><br />' +
        'La Galleria, la prima italiana inserita in un sito web completamente navigabile, è la ricostruzione del piano museale di Palazzo Crova, presso Nizza Monferrato, che presenta una mostra dedicata alla Collezione Art ‘900 di Davide Lajolo. <br /><br />' +
        'La Galleria Virtuale Davide Lajolo raccoglie 24 opere fotografiche a cura dell’Agenzia Visiva Mikelerio e 5 opere pittoriche della Collezione Art ‘900 di Davide Lajolo in un racconto immerso nella cultura monferrina. <br /><br />' +
        'Un progetto nato dall’esigenza di ridefinire la fruibilità e l’accessibilità artistica ripensandone gli schemi.',
    'projects.page.head_2': '"Æ ArtEtica"',
    'projects.page.subhead_2': 'L\'Arte Solidale e Sostenibile',
    'projects.page.releaseDate_2': '18-19 Luglio 2019',
    'projects.page.body_2': 'L’evento “Æ ArtEtica | L’Arte Solidale e Sostenibile” tenutosi il 19 Luglio 2019 presso FuoriLuogo Asti, ha raccolto la candidatura di oltre 50 artisti provenienti da tutta Italia, la presenza di una giuria ' +
        'd’eccellenza, e di oltre 400 visitatori al vernissage. <br /><br />Giuria presieduta da Davide Fabbro, Dottore Commercialista e Art Advisor e coadiuvata dall’Architetto Elisabetta Tovo, membro del C.d.A. di Fondazione Asti Musei. <br /><br />' +
        'Il bando, rivolto ad artisti italiani di qualsiasi espressione artistica pittorica e scultorea, ha visto esibire le opere dei 7 finalisti che meglio hanno saputo interpretare la propria visione di arte solidale attraverso l’utilizzo di metodi o materiali sostenibili.  <br /><br />' +
        'Ha vinto Michele Liparesi con la sua scultura “Agglomerato Urbano”: un vero e proprio spazio urbano, creato con il riutilizzo dei materiali industriali più vari. Scultura che si reinventa al calar del buio, con un gioco di luci che ' +
        'rivitalizza l’agglomerato e che lo rende parte integrante e funzionale dello spazio. <br /><br />',
    'projects.page.head_3': 'ForArts Exhibitions',
    'projects.page.subhead_3': 'Your Art. Anytime. Anywhere.',
    'projects.page.releaseDate_3': 'Coming Soon',
    'projects.page.body_3': 'ForArts Exhibitions è una piattaforma di social art dedicata all’allestimento di mostre ed esperienze virtuali in gallerie 3D customizzabili, completamente navigabili e condivisibili.<br /><br />' +
        'L’idea della piattaforma nasce dall’esigenza di dar sfogo alla visione di ForArts per un mondo dell’arte più inclusivo, accessibile e meritocratico, ed in corrispondenza alle chiusure dettate dal regime pandemico: crediamo in un’arte libera da barriere e vincoli economici e territoriali, in un’arte accessibile e universale. <br /><br />' +
        '<b>Quando sarà disponibile al pubblico?</b><br /><br /> Presto lanceremo una fase di testing della piattaforma (riservata in esclusiva a non più di 50 utenti) dedicata alla cura degli ultimi dettagli prima del lancio finale: il test ci sarà utile per costruire una rete di funzionalità' +
        'e servizi di facile e veloce utilizzo creati su misura per le vostre necessità artistiche.<br /><br /> Sei interessato a candidarti per la fase di test oppure vuoi saperne di più sulle tempistiche e sulle funzionalità? Contattaci alla ' +
        'mail <a href=\"mailto:creative@forarts.info\" target=”_blank”>creative@forarts.info</a> o inviaci la tua candidatura attraverso la nostra <a href=\"ContactUs\" target=”_blank” >sezione dedicata</a>, sarà nostra premura ricontattarti entro 24 ore, 7 giorni su 7.<br /><br />' +
        '<b>A chi sono dedicati i servizi della piattaforma?</b><br /><br /> La piattaforma è dedicata ad artisti di qualsiasi nazionalità e corrente artistica, a gallerie pubbliche e private e a professionisti del mondo dell’arte. <br /><br />' +
        'Categorie di artisti: Pittori, Artisti Digitali, Disegnatori, VideoMakers e Fotografi di qualsiasi espressione e corrente artistica.<br /><br /> Categorie di gallerie: Musei e Gallerie Pubbliche e Private di arte Antica, Moderna e Contemporanea. <br /><br />Professionisti: Critici, Collezionisti, Curatori e Art Advisors. <br /><br />' +
        '<b>Come funziona la piattaforma?</b> <br /><br /><b>Per gli artisti</b> <br /><br /> Una volta registrato sulla piattaforma avrai accesso alle funzionalità di creazione del tuo profilo e di allestimento delle gallerie d’arte virtuali (GAV): potrai raccontarti, promuoverti e caricare' +
        'le opere sul tuo profilo per utilizzarle direttamente nell’allestimento delle tue gallerie virtuali. <br /><br /><b>Per musei, gallerie pubbliche e private</b> <br /><br />Una volta registrato sulla piattaforma, in quanto garante per la galleria, avrai accesso alle funzionalità di creazione e diffusione del tuo profilo: potrai raccontare' +
        'la tua galleria, promuoverla e caricare le opere per utilizzarle direttamente nell’allestimento delle tue gallerie virtuali. <br /><br /><b>Per i professionisti</b> <br /><br />Una volta registrato sulla piattaforma avrai accesso alle funzionalità di creazione del tuo profilo e di allestimento delle' +
        'gallerie d’arte virtuali (GAV): potrai raccontarti, promuoverti per ampliare il tuo spettro clientelare e caricare le opere sul tuo profilo per utilizzarle direttamente nell’allestimento delle tue gallerie virtuali per mostrare i tuoi progetti o le opere dei tuoi clienti. <br /><br /><b>Categorie d’arte supportate nell’allestimento 3D</b> <br /><br />' +
        'Le categorie d’arte che la nostra tecnologia supporta e permette di esporre in scenari tridimensionali sono Pittura, Arte Digitale (ivi incluse le GIF Animation), Fotografia, Disegno e VideoArte di qualsiasi espressione e corrente artistica. <br /><br /> <b>Promozione e accessibilità</b> <br /><br />' +
        'Le gallerie virtuali saranno accessibili da qualsiasi utente (anche non iscritto) tramite la tua pagina di profilo sulla piattaforma ForArts Exhibitions o tramite la sezione dedicata alle esperienze espositive 3D. <br /><br />In quanto piattaforma web sarà inoltre possibile diffondere ogni galleria tramite un semplice link: potrai inviare le tue gallerie' +
        'ai tuoi contatti, inserirle nel tuo sito web personale e nel portfolio artistico per avere sempre con te esperienza espositive di qualità che raccontino la tua visione artistica e mostrino le tue opere. <br /><br /><b>Collaborazione tra utenti</b> <br /><br />' +
        'Artisti, gallerie e professionisti potranno collaborare nell’ideazione, nell’allestimento e nella promozione di un’esperienza espositiva multi-artistica: la multidisciplinarietà e l’unione di competenze e tecniche artistiche garantirà una maggiore diffusione grazie anche al network dei tuoi collaboratori. <br /><br />' +
        '<b>Funzionalità aggiuntive | Ricrea dal vero o progetta la tua galleria personalizzata</b> <br /><br />Vuoi ricreare la tua galleria d’arte o progettarne una appositamente studiata per la tua arte? Che tu sia un artista, una galleria o un professionista potrai richiedere al team tecnico di ForArts un preventivo in qualsiasi momento scegliendo il “Custom Pack”! <br /><br />' +
        '<b>Integrazioni ed aggiornamenti futuri</b> <br /><br />Siamo già al lavoro per l’implementazione di un e-commerce integrato alla piattaforma per garantirti un processo di vendita delle tue opere certificato, sicuro e veloce. <br /><br />' +
        'Stiamo inoltre lavorando per l’inserimento di una chat integrata alla piattaforma affinchè tu possa contattare con più facilità gli altri utenti ed ampliare il tuo network. <br /><br /><b>Idee su cui stiamo lavorando | NFT marketplace</b> <br /><br />' +
        'Stiamo valutando il possibile inserimento di un marketplace di NFT che garantisca all’artista la paternità e i diritti di opere digitali o su file corrispondenti ad opere reali, per la vendita degli stessi o di intere collezioni e gallerie. Non conosci la blockchain ed il mondo degli NFT? scopri di più nell’articolo dedicato sul nostro Arts Magazine. <br /><br />' +
        '<b>Contatti</b> <br /><br />Hai dubbi, curiosità o necessiti di maggiori informazioni riguardo alla piattaforma? Contattaci alla mail <a href=\"mailto:creative@forarts.info\" target=”_blank”>creative@forarts.info</a>, il nostro staff è a tua disposizione.',
    // CALLS PAGE 
    'calls.page.head_1': 'IVAP 2020',
    'calls.page.subhead_1': 'International Virtual Art Prize',
    // CONTACTUS PAGE 
    "contact.gallery": "La Tua Galleria",
    "contact.exhibit": "La Tua Esposizione",
    "contact.expo": "La Tua Esperienza",
    "contact.art": "La Tua Arte",
    "contact.masterpiece": "I Tuoi Capolavori",
    "contact.artwork": "Le Tue Opere",
    "contact.portfolio": "Il Tuo Portfolio",
    "contact.career": "La Tua Carriera",
    "contact.work": "I Tuoi Lavori",
    "contact.link": "Un Semplice Link",
    "contact.social": "I Tuoi Social",
    "contact.web": "Il Tuo Sito Web",
    "contact.create": "Crea",
    "contact.show": "Mostra",
    "contact.promote": "Promuovi",
    "contact.share": "Con",



}