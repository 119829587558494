


// Naviga via scroll verso l'ID fornito. 

import { breakpointMobile } from "./global-variables";


// Se l'id e' errato non succede niente
export function goToComponentBy(s: string, h: any, dontScrollUp?: boolean) {
    // var ele: HTMLElement | null = document.getElementById(s);
    // var top: number = ele?.offsetTop || 0;
    // if (ele) {
    // window.scrollTo({ top: top > 50 ? top - 50 : top, behavior: 'smooth' })
    // }
    if (h) {

        h.push(`/${s}`);
    } else {
        window.location.href = ("./" + s);
    }
    if (!dontScrollUp) {
        window.scrollTo({ top: 0 });
    }
}

export function goToBlank(url: string) {
    window.open(url, '_blank');
}


export function getParams(url: string) {
    var params: any = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};



export function getPreviewString(fullString: string, length: number, fullLength?: boolean): string {

    if (fullLength) {
        return fullString;
    } else {
        const l = window.innerWidth < breakpointMobile ? (length / 100) * 70 : length;
        const body = fullString
            // PRIMI 140 CARATTERI
            .substring(0, l)
            // SPLITTO PER SPAZIO
            .split(' ')
            // VERIFICO SE USARE ANCHE L'ULTIMA PAROLA DELL'ARRAY CONTROLLANDO SE E' SPEZZATA
            .slice(0, fullString.substring(0, l).split(' ').length - (fullString.charAt(l + 1) === ' ' ? 1 : 2))
            // UNISCO L'ARRAY IN UNA STRINGA
            .join(" ")
            // RIMUOVO EVENTUALI SPAZI SUPERFLUI
            .trim()
            // AGGIUNGO '..' o '...' IN BASE ALL'ULTIMO CARATTERE
            + (!fullString.charAt(l + 1) ? '' : (fullString.charAt(l) === '.' ? '..' : '...'));
        // console.log(`Preview with ${body.length} chars`);
        // console.log(`Preview with ${body.split(" ").length} words`);
        return body.toString();
    }
}