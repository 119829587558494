export const en_translations =
{
    // GLOBAL 
    "global.ambiente": "Ambiente",
    "general.expand": "Expand",
    "general.contactus": "Contact Us",
    "general.reduce": "Reduce",
    // CAROUSEL 
    "carousel.title_1": "Virtual Gallery Davide Lajolo",
    "carousel.description_1": 'A  <b>virtual</b> exhibition <b>experience</b> in Palazzo Crova. <br/> A sweet marriage between <b>tradition</b> and <b>innovation</b>. ' +
        '<br/>Monferrato\'s culture declined into <b>art</b> and <b>photography</b>.',
    "carousel.title_3": "Your Virtual Gallery",
    "carousel.description_3": "We create the perfect space for <b>Art</b>. <br/>Unleash your <b>creativity</b>. <br/>Discover how to create <b>your</b> Virtual Art Gallery.",
    "carousel.title_2": "Our Projects",
    "carousel.description_2": 'Contemporary Art\'s <b>Call for Artists</b>. <br/>For an <b>accessible</b>, <b>inclusive</b> and <b>meritocratic</b> ' +
        'art. <br>Discover & Sign-Up for the active Calls!',
    "carousel.button": "Visit The Exhibition",
    // MAGAZINE
    "magazine.subtitle": 'ForArts online magazine',
    "magazine.button": "Read Articles",
    "magazine.description": 'Online Art Magazine, for the <b>art</b>, the <b>artist</b> and the <b>innovation</b>. For Contemporary Art: ' +
        '<i>insights</i>, <i>curiosity</i>, <i>data</i> and <i>facts</i> around <b>Art</b> world. <br/><br/> For the Emerging Artist: a dedicated ' +
        'guide about the professional artistic figure growth. <br/><br/>For art\'s <b>Digital Innovation</b>: A journey into <b><i>ForArts</i></b> Virtual Galleries!',
    // CALL 
    "call.subtitle": 'Inclusive and meritocratic Art',
    "call.description": 'Contemporary Art Call For Artists for Emerging Artists: <b>discover</b> the new calls and <b>sign  ' +
        'up</b> for the active ones. <br /><br /> Call for Artists for exhibition <b>unique</b> context in <b><i>ForArts</i></b>\' ' +
        'Virtual Galleries: Show Your Art. <br /><br /> Are you a <b>Painter</b>, a <b>Photographer</b>, a <b>Sculptor</b>, a <b>Graphic ' +
        'Designer</b> or a <b>VideoMaker</b>? <br /><br /> <b>Send us your application!</b>',
    "call.button": "Discover Calls",
    // SOCIAL 
    "social.description": "<b>Share</b> your Masterpieces with our Community! <br /><br /> <b>Discover</b> news about Calls, Projects and Events! <br /><br /> Join <b>ForArts</b>' World!",
    // ABOUT 
    "about.description": 'Our <b>Mission</b> is to give light to the artistic production of the Emerging Artist. ' +
        '<br /><br /> We adapt to the Contemporary Art\'s <b>new</b> <b>digital</b> and <b>comunicative</b> needs, ' +
        'through our <b>virtual</b> galleries. <br /><br /> Inspired 360° by <b>Visual</b> Arts and dedicated to their ' +
        'creators: <i>Painters</i>, <i>Sculptors</i>, <i>Photographers</i>, <i>Graphics</i> and <i>VideoMakers</i>.',
    "about.button": "Discover Our Project",
    // ABOUT PAGE MENU 
    "about.page.menu_1": "The Project",
    "about.page.menu_2": "Æ ArtEtica",
    "about.page.menu_3": "Mission",
    "about.page.menu_4": "ForArts Cultural Association",
    "about.page.menu_5": "Vision",
    "about.page.menu_6": "Davide Lajolo Virtual Gallery",
    "about.page.menu_7": "ForArts Exhibitions",
    // ABOUT PAGE
    "about.page.head_1": "The Project",
    "about.page.subhead_1": "Culture &amp; Innovation",
    "about.page.description_1": 'ForArts was born in March 2019 from an idea of Edoardo and Gianluca, two graduating students in <i>"Design & Visual Communication"</i> at Polytechnic ' +
        'Of Turin and <i>"Business & Management"</i> at Turin University of Studies with the Art passion, who found a great ally in Francesco, graduated in <i>"Pro Audio Engineering"</i> at CPM Music ' +
        'Institute in Milan. <br/><br/> This competence <b>union</b> allow us to give life to our <b>ideas</b>, in a process that reminds the turbulent path which assist the artist from the <b>spark</b> to the ' +
        '<b>canvas</b>. <br/><br/>  ForArts was born with the idea to enhance art and the emergin artist through a dedicated digital platform and through exhibition experience where <b>art</b>, <b>culture</b>, ' +
        '<b>sustainability</b> and <b>technology</b> are permanent drivers.',
    "about.page.head_2": "Æ ArtEtica",
    "about.page.subhead_2": "L'Arte Solidale e Sostenibile",
    "about.page.description_2": '<b><i>“ArtEtica | L’Arte Solidale e Sostenibile”</b></i> which took place on 19th July 2019 @ "FuoriLuogo Asti" ' +
        ', with applies from more than 50 artists nationwide, a selection board of <b>excellence</b> headed by <b><i>Davide Fabbro</b></i>, ' +
        'Professional Accountant & Art Advisor. 400 Vernissage Visitors wittnessed ForArts\' Mission Launch.',
    "about.page.head_3": "Mission",
    "about.page.subhead_3": "Innovate To Preserve",
    "about.page.description_3": '“Adapt. Respond. Transform. <b><fs>Succeed</fs></b>.”: as our slogan says, our Mission is ' +
        'to enhance the artistic production of the <b>emerging</b> artist, adapting it to the new <b>communication</b> and <b>digital</b> needs of the contemporary art. ',
    "about.page.head_4": "ForArts Cultural Association",
    "about.page.subhead_4": "For The Artist's Growth",
    "about.page.description_4": 'ForArts Cultural Association with the purpose to promote <b>art</b>, <b>culture</b> and <b>artists</b> of any kind of expression: <br /><br /> <b>painting</b>, <b>sculpture</b>, <b>photography</b>, <b>graphics</b> and <b>videomaking</b>',
    "about.page.head_5": "Vision",
    "about.page.subhead_5": "Toward An Accessible & Meritocratic Art",
    "about.page.description_5": 'ForArts\' Vision is to work in support of an <b>accessible</b> and <b>inclusive</b> art, by re-evaluating function, fruition and spaces, helped by new technologies. We want to create a <b>meritocratic</b> model of art ' +
        'through ForArts Virtual Galleries.',
    "about.page.head_6": "Davide Lajolo Virtual Gallery",
    "about.page.subhead_6": "Monferrato Art & Culture ",
    "about.page.description_6": 'For the <i>"Festival del Paesaggio Agrario XII Edizione"</i>, ForArts presents the first Italian Virtual Art Gallery Online' +
        'and gives life to its Vision. The <b><i>"Davide Lajolo Virtual Gallery"</i></b> is the reconstruction of the museum in Palazzo Crova, Nizza M.To, ' +
        'home of the <b><i>"Collezione Davide Lajolo Art ‘900"</i></b>, a gallery of great italian artist of the XX century. The Gallery will have a section dedicated to great italian painters, such as Guttuso, Nerone, Vacchi and Zigaina ' +
        'and a photographic section with photos by <i>"Agenzia Visiva Mikelerio"</i>.',
    "about.page.head_7": "ForArts Exhibitions",
    "about.page.subhead_7": "Art Looks to the Future",
    "about.page.description_7": 'ForArts Exhibitions is a social art platform (currently under development) designed to create exhibitions and <b>virtual experiences</b> in customizable, shareable and fully navigable 3D galleries. <br /><br />' +
        'The platform is dedicated to <b>artists</b> (painters, digital artists, photographers, designers and videomakers), <b>exhibition spaces</b> (museums, public and private galleries) and <b>professionals</ b> from the art world (art advisors, collectors, critics and curators). <br /><br />' +
        'ForArts Exhibitions will allow users to set up and promote virtual galleries, to <b>collaborate</b> with other users, to <b>apply</b> to Call for Artists and <b>monetize</b> artistic production. <br /><br />' +
        'We will soon launch a test phase: are you interested in applying or would you like to know more about <b>timing</b> and <b>features</b>? Contact us via <a href=”mailto:creative@forarts.info” target=”_blank”>creative@forarts.info</a>, our team is at your disposal.',
    // ARTICLE PAGE 
    'article.page.head': 'Arts Magazine Overview',
    'article.page.subhead': 'Arte, Innovazione e Sostenibilità',
    'article.page.article': '<forarts>Art<fs>s</fs></forarts> <b>Magazine</b> is the online magazine of the non-profit Cultural Association <forarts>ForArts</forarts>. <br />' +
        'It is the combination of <b>Contemporary Art</b>, <b>Innovation</b> and <b>Sustainability</b>: the cornerstones of the Project. <br />' +
        'A project whose vision arises from the need to give voice to the one to whom contemporary artistic production is owed: the <b>artist</b>. <br />' +
        'Through an <b>innovative</b> communication method, designed ad hoc to adapt the new needs of the contemporary artist. <br/>' +
        'With the ForArts Virtual Galleries it is now possible to create art spaces specifically designed for the artist and his art: reducing the typical costs of ' +
        'exhibition and promotion and making digital art a meritocratic, accessible and functional tool for the artist\'s <b>growth</b>. <br /> ' +
        'It will now be possible for the emerging artist to have a solitary art gallery and their portfolio within reach of their website, ' +
        'to be promoted through their artistic CV, their social channels and their website or to be disseminated to critics operators of the art world. <br />' +
        'The gallery will still be <b>permanently</b> present on the official ForArts website, in the Virtual Art Galleries section. <br />' +
        'A simple website, completely dedicated to an exhibition experience designed on the needs of the artist: an innovative method, born in times of Covid-19, to make technology a means at the service of art. <br />' +
        'To make art always usable and <b>accessible</b>: an internet connection will be enough to access the gallery. <br />' +
        'To make art <b>meritocratic</b>: by reducing the typical exhibition costs that today make art elitist and increasing its power of diffusion thanks to technology. ' +
        'To make art a tool for <b>cultural</b> dissemination: Visit the Davide Lajolo Virtual Gallery and find out how it works: <a href=\"galleriadavidelajolo.forarts.it\">galleriadavidelajolo.forarts.it</a> ' +
        'With the aim of making known the production of the Emerging Artist. <br />' +
        'Dedicated to <b>visual</b> and <b>digital</b> art of any expression and technique: <i>painting</i>, <i>sculpture</i>, <i>photography</i>, <i>graphics</i> and <b>video</b>. <br />' +
        '<forarts>Art<fs>s</fs></forarts> <b>Magazine</b> embraces the artistic context in its entirety, in order to propose a synthesis that makes it a useful, effective and efficient ' +
        'tool for the Transformation and Growth of the Artist of the Emerging Artis' +
        '<br/><br/> <floatright><forarts>ForArt<fs>s</fs> Cultural Association</forarts></floatright> ',
    // MAGAZINE PAGE 
    'magazine.page.head.1': 'Arts Magazine Overview',
    'magazine.page.subhead.1': 'Art, Innovation, Sustainability',
    'magazine.page.preview.1': 'Arts Magazine is the ForArts Cultural Association magazine about art.' +
        'A marriage between Contemporary Art, Innovation and Systainability: Arte Contemporanea, Innovazione e Sostenibilità: our project\'s cornerstone.' +
        'The vision of this project is born because we want to give voice to who is the maker of the contemporary art production: the artist',
    'magazine.page.head.2': 'Online Magazine #Art',
    'magazine.page.subhead.2': 'A Panoramic Tour in ForArts\' World',
    'magazine.page.preview.2': '#Art\'s Concept creation and development is a consequence of multiple kind of needs.' +
        'First and foremost, there\'s a need to create a model that allows to communicate effectively with the actors of the art world. Giving them useful tools such as:',
    // PROJECTS PAGE
    'projects.page.head_1': '"Davide Lajolo" Virtual Gallery',
    'projects.page.subhead_1': 'A Journey into Monferrato\'s Culture',
    'projects.page.releaseDate_1': 'October 24th, 2020',
    'projects.page.body_1': 'On 24 October 2020, on the occasion of the "Festival of the Agricultural Landscape XII Edition 2020", the non-profit association ForArts ' +
        'launches and inaugurates the first Italian virtual gallery that is completely navigable on a website. <br /><br />The project, born in collaboration with the Davide Lajolo ' +
        'Cultural Association, the Association for the Heritage of Wine Landscapes of Langhe, Roero and Monferrato, organizers of the Festival, and with the artistic ' +
        'contribution of the FuoriLuogo Asti Association and the Mikelerio Visual Agency , leads to the creation of the Davide Lajolo Virtual Gallery. <br /><br />The Gallery, ' +
        'the first in Italy to be included in a fully navigable website, is the reconstruction of the museum floor of Palazzo Crova, in Nizza Monferrato, which presents ' +
        'an exhibition dedicated to Davide Lajolo\'s Art \'900 Collection. <br /><br />The Davide Lajolo Virtual Gallery collects 24 photographic works by the Mikelerio Visual Agency ' +
        'and 5 paintings from the Art \'900 Collection by Davide Lajolo in a story immersed in the Monferrato culture.<br /><br /> A project born from the need to redefine the usability and ' +
        'artistic accessibility by rethinking the schemes.',
    'projects.page.head_2': 'Æ ArtEtica',
    'projects.page.subhead_2': 'Solidary and Sustainable Art',
    'projects.page.releaseDate_2': 'July 18th-19th, 2020',
    'projects.page.body_2': 'The "Æ ArtEtica | Solidary and Sustainable Art" held on 19 July 2019 at FuoriLuogo Asti, received the candidacy of over 50 artists from all over Italy, ' +
        'the presence of a jury of excellence, and over 400 visitors to the vernissage. <br /><br /> Jury chaired by Davide Fabbro, Chartered Accountant and Art Advisor and assisted by ' +
        'the architect Elisabetta Tovo, member of the Board of Directors of the Asti Musei Foundation. <br /><br /> The call, aimed at Italian artists of any pictorial and sculptural artistic ' +
        'expression, saw the works of the 7 finalists who best interpreted their vision of solidarity art through the use of sustainable methods or materials. <br /><br /> The prize and the ' +
        'Certificate were awarded to Michele Liparesi with his sculpture "Urban Agglomeration": a real urban space, created through the reuse of various industrial materials. ' +
        'Sculpture that reinvents itself after dark, with a play of lights that revitalizes the agglomeration and makes it an integral and functional part of the space.',
    'projects.page.head_3': 'ForArts Exhibitions',
    'projects.page.subhead_3': 'Your Art. Anytime. Anywhere.',
    'projects.page.releaseDate_3': 'Coming Soon',
    'projects.page.body_3': '<b>What is ForArts Exhibitions?</b> <br /><br />ForArts Exhibitions is a social art platform dedicated to setting up virtual exhibitions and experiences in customizable, fully navigable and shareable 3D galleries. <br /><br />' +
        'The idea of ​​the platform comes from the need to give vent to ForArts\' vision for a more inclusive, accessible and meritocratic world of art, and in correspondence with the closures dictated by the pandemic regime: we believe in an art free from barriers and economic and territorial constraints, for an accessible and universal art <br /><br />' +
        '<b>When will it be available to the public?</b> <br /><br /> We will soon launch a testing phase of the platform (reserved exclusively for no more than 50 users) dedicated to taking care of the last details before the final launch: the test will be useful for building ' +
        'a network of easy and quick to use features and services created on fit for your artistic needs. <br /><br /> Are you interested in applying for the test phase or would you like to know more about the launch timing and features? Contact us at <a href=\"mailto:creative@forarts.info\" target=”_blank”>creative@forarts.info</a> or send your application through our <a href=\"https://forarts.it/ContactUs\" target=”_blank” >dedicated section</a>, it will be our pleasure to contact you within 24 hours, 7 days a week. <br /><br />' +
        '<b>Which categories are the platform services dedicated to?</b> <br /><br />The platform is dedicated to artists of any nationality and artistic movement, to public and private galleries and professionals from the art world. <br /><br />Categories of artists: Painters, Digital Artists, Draftsmen, VideoMakers and Photographers of any expression and artistic current. <br /><br />' +
        'Categories of galleries: Museums and Public and Private Galleries of Ancient, Modern and Contemporary art. <br /><br />Professionals: Critics, Collectors, Curators and Art Advisors. <br /><br /><b>How does the platform work?</b> <br /><br /><b>For artists</b> <br /><br /> Once registered on the platform, you will have access to the functions for creating your profile and setting up virtual art galleries: ' +
        'you can tell about yourself, promote yourself and upload the works to your profile to use them directly in setting up your virtual galleries. <br /><br /> <b>For museums, public and private galleries</b> <br /><br />' +
        'Once registered on the platform, as guarantor for the gallery, you will have access to the creation and diffusion features of your profile: you can tell about your gallery, promote it and upload your works to use them directly in setting up your virtual galleries. <br /><br /> <b>For professionals</b> <br /><br />' +
        'Once registered on the platform you will have access to the functions of creating your profile and setting up virtual art galleries: you can tell about yourself, promote yourself to broaden your clientelist spectrum and upload the works to your profile to use them directly in the set-up of your virtual galleries to show your projects or the works of your clients. <br /><br />' +
        ' <b>Art categories supported in the 3D set-up</b> <br /><br />The categories of art that our technology supports and allows to exhibit in three-dimensional scenarios are Painting, Digital Art (including GIF Animation), Photography, Drawing and VideoArt of any artistic expression and current. <br /><br /><b>Promotion and accessibility</b> <br /><br />' +
        'The virtual galleries will be accessible by any user (even non-registered) through your profile page on the ForArts Exhibitions platform or through the section dedicated to 3D exhibition experiences. <br /><br />As a web platform it will also be possible to spread each gallery through a simple link: you can send your galleries to your contacts, insert them on your personal website and in the artistic portfolio to always have quality exhibition experiences with you that tell your artistic vision and show your works. <br /><br /><b>Collaboration between users</b> <br /><br />' +
        'Artists, galleries and professionals will be able to collaborate in the conception, preparation and promotion of a multi-artistic exhibition experience: the multidisciplinarity and the union of skills and artistic techniques will ensure greater diffusion thanks to the network of your collaborators. <br /><br /> <b>Additional Features | Recreate or design your own customized gallery</b> <br /><br />' +
        'Do you want to recreate your art gallery or design one specifically designed for your art? Whether you are an artist, a gallery or a professional you can ask the ForArts technical team for a quote at any time by choosing the "Custom Pack"! <br /><br />' +
        '<b>Future integrations and updates</b> <br /><br />We are already working on the implementation of an integrated e-commerce platform to guarantee you a certified, safe and fast sales process for your works. <br /><br />' +
        'We are also working on the inclusion of an integrated chat on the platform so that you can more easily contact other users and expand your network. <br /><br /><b>Ideas we are working on | NFT marketplace</b> <br /><br />We are evaluating the possible inclusion of an NFT marketplace that guarantees the artist the authorship and rights of digital works or files corresponding to real works, for the sale of the same or of entire collections and galleries. New to the blockchain and the world of NFTs? find out more in the dedicated article in our Arts Magazine. <br /><br />' +
        '<b>Contacts</b> <br /><br /> Do you have doubts, curiosities or need more information about the platform? Contact us at <a href=\"mailto:creative@forarts.info\" target=”_blank”>creative@forarts.info</a>, our staff here for you',

    // CALLS PAGE 
    'calls.page.head_1': 'IVAP 2020',
    'calls.page.subhead_1': 'International Virtual Art Prize',
    // CONTACTUS PAGE
    "contact.gallery": "Your Gallery",
    "contact.exhibit": "Your Exhibition",
    "contact.expo": "Your Experience",
    "contact.art": "Your Art",
    "contact.masterpiece": "Your Masterpieces",
    "contact.artwork": "Your Artworks",
    "contact.portfolio": "Your Portfolio",
    "contact.career": "Your Career",
    "contact.work": "Your Works",
    "contact.link": "A Simple Link",
    "contact.social": "Your Socials",
    "contact.web": "Your Website",
    "contact.create": "Create",
    "contact.show": "Show",
    "contact.promote": "Promote",
    "contact.share": "Share",

}