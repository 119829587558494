import React from 'react';
import style from './loading.module.scss';


export default function Loading() {
    return (
        <section className={`${style.container} flex-center-all`} >
            <i className={`material-icons animate-spin ${style.loadingIcon}`} >cached</i>
        </section >
    )
}
